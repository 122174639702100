<template>
  <div class="container mt-5   px-md-5" style="margin-left:auto; margin-right:auto;">
    <div class=" mb-4">
        <h2 class="text-left" style="text-transform: capitalize;">{{title}}</h2>
    </div>
    <div class="container-fluid p-0">
      <button @click="showModal(1)" class="btn-primary"><i class="fa fa-plus mr-2"></i>Descargable</button>
    </div>
    <div class="my-4 mb-2">
      <!-- <h2 class="text-left">Mis solicitudes</h2> -->
    </div>
    
    
    <div class="bg-gray ">
      <b-table 
          hover
          responsive 
          :items="table_list" 
          :fields="fields" 
          :current-page="currentPage" 
          :per-page="perPage" 
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
      >
      <template #cell(name)="row">
          {{ row.value.first }} {{ row.value.last }}
      </template>
              <template #cell(fecha_inicio)="data">
                  <span  >{{ data.item.fecha_fin != null ? $moment(data.item.fecha_inicio).utc().format("DD-MM-YYYY") : '' }}</span>
              </template>
              <template #cell(fecha_fin)="data">
                  <span  >{{ data.item.fecha_fin != null ? $moment(data.item.fecha_fin).utc().format("DD-MM-YYYY") : '' }}</span>
              </template>
              <template #cell(action)="data">
                  <button @click="showModal(2, data.item)" class="btn-white-outline-small mx-1"><i class="fa fa-pen"></i></button>
                  <button @click="deleteItem(data.item)" class="btn-primary-small mx-1"><i class="fa fa-trash"></i></button>
              </template>
      <template #row-details="row">
          <b-card>
          <ul>
              <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
          </b-card>
      </template>
      </b-table>
        <div>
          
      </div>
    </div>
    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" hide-goto-end-buttons hide-ellipsis prev-text="Prev" next-text="Next" align="right"></b-pagination>

  
  <!-- editar producto -->
  <b-modal ref="modal-item2" hide-footer >
    <template #modal-title>
      {{modalText}}
    </template>

    <template>
      <form @submit.prevent="saveData()" style="padding:10px">
        <!-- <div class="row">
          <div class="col-12">
            <label for="">Nombre</label>
            <input required class="form-control" v-model="row.titulo" type="text" name="" id="">
          </div>
        </div> -->
        <div class="row mt-1">
          <div class="col-12">
            <label for="">Texto boton</label>
            <input class="form-control" v-model="row.texto_boton" type="text" name="" id="">
            <!-- <b-textarea cols="5" rows="5" required class="form-control" v-model="row.descripcion" type="text" name="" id="" /> -->
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <label for="">Descargable</label><br>
            <input type="file" id="file1" ref="file1" v-on:change="onFileSelected('descargables',1)"/><br>
            <input class="form-control" v-model="row.url" type="text" name="" id="">
          </div>
        </div>        
        <div class="row mt-4">
          <div class="col-12">
            <b-button type="submit" class="btn-primary-small mx-1 float-right ml-3">Guardar</b-button>
            <b-button @click="$refs['modal-item2'].hide()" class="btn-white-outline-small float-right">Cancelar</b-button>
          </div>
        </div>
      </form>
    </template>
  </b-modal>  
  </div>
</template>

<script>
export default {
  components: {
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      selected: null,
        categorias: [
          { id: 1, text: 'Categoría' },
          { id: 2, text: 'This is First option' },
          { id: 3, text: 'Selected Option' },
          { id: 4, text: 'This is an option with object value' },
          { id: 5, text: 'This one is disabled'}
        ],
        subcategorias: [
        ],
        Producto: [
          { id: 1, text: 'Producto' },
          { id: 2, text: 'This is First option' },
          { id: 3, text: 'Selected Option' },
          { id: 4, text: 'This is an option with object value' },
          { id: 5, text: 'This one is disabled'}
        ],
      perPage: 25,
      currentPage: 1,

      table: "descargables",
      modalText: 'Nueva Ficha',
      title: 'Descargables',
      items: [],

      table_list: [],
      row:{},
      categoria_id: 1,
      fields: [
          { key: 'id', label: 'id', sortable: true, sortDirection: 'desc', class: 'text-center' },
          { key: 'texto_boton', label: 'texto boton', sortable: true, class: 'text-center' },
          // { key: 'descripcion', label: 'Descripción', sortable: true, class: 'text-center' },
          { key: 'action', label: 'Acciones',class: 'text-center'},
        { sortable: true, sortByFormatted: true, filterByFormatted: true },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      id:0,
      file1: null,
      filename1: null,      
    };
  },
  async mounted() {
      this.id = this.$route.params.id
    this.$isLoading(true)
    await this.getData();
    this.$isLoading(false)
  },
  methods: {
    onFileSelected(type, item){
      if(item==1){
        this.file1 = event.target.files[0];
        let extension = this.file1.name.split('.')[1];
        this.filename1 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename1 = type + '_' + this.filename1 +'.'+ extension;
       
      }

    },      
    showModal(type, item){
      console.log(item)
      if(type == 2){
        this.modalText = 'Editar Ficha'
        this.row = item
      }
      else{
        this.row = {}
        this.modalText = 'Nueva Ficha'
      }
      this.$refs['modal-item2'].show()
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("get", { path: this.table + '/getbyProducto/' + this.id});
        //console.log(res)
        if(res.length>0){
          this.table_list = res
          this.totalRows = this.table_list.length
        }
        else{
          this.table_list = []
          this.totalRows = this.table_list.length
        }
      } catch (error) {
        this.table_list = []
        this.totalRows = this.table_list.length
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error: " + error,
        });
      }
    },
    async saveData() {
      try {
        let req
        let uploadImg
        this.$isLoading(true)        
        if(this.filename1!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename1 ,file: this.file1})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.url = 'https://tls.naotravelco.com/img/' + this.filename1
          }
        }        
        this.row.producto_id = this.id

        if(this.row.id){
          let id = this.row.id
          let data = this.row
          delete data.id
          req = await this.$store.dispatch("put", { path: 'descargables/modify/'+ id, data: data });
        }
        else{
          req = await this.$store.dispatch("post", { path: this.table + '/new', data: this.row });
        }
        this.$isLoading(false)
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Datos guardados correctamente!",
        });
        this.$refs['modal-item2'].hide()
        this.getData();
      } catch (error) {
        this.$isLoading(false)
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error, revisar datos.",
        });        
        //alert(error);
      }
    },
    ///////////////

    async deleteItem(item) {
      try {
        console.log(item.id)
        const sure = await confirm(
          "¿Está seguro que desea eliminar este elemento?"
        );
        if (!sure) {
          return;
        }

        const req = await this.$store.dispatch("delete", {
          path: this.table +'/' + item.id,
        });
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Registro eliminado correctamente!",
        });        
        this.getData();
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Opps!",
          text: "Error: " + error,
        });        
        console.log(error)
        //alert(error);
      }
    },
  },
};
</script>

<style>

 

</style>